$(function () {
    $('.slider').slick({
        autoplay: true,
        draggable: true,
        fade: true,
        speed: 1000,
        arrows: true,
        dots: true,
        prevArrow: '<a href="javascript:;" class="prev"><i class="fa fa-caret-left" aria-hidden="true"></i></a>',
        nextArrow: '<a href="javascript:;" class="next"><i class="fa fa-caret-right" aria-hidden="true"></i></a>'
    });


    $('.mobile-menu-button').click(function () {
       $('header').toggleClass('menu-active');
    });


    $("span[id^=more]").parent().remove();


});